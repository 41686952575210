import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../../../PageHeader/PageHeader";
import { ActiveStatuses, PendingStatuses, SuspendedStatuses } from "../../MyDrivers/types";
import { ApprovedDriverPanel } from "./ApprovedDriver/ApprovedDriverPanel";
import { ArchivedDriverPanel } from "./ArchivedDriver/ArchivedDriverPanel";
import { PendingDriverPanel } from "./PendingDriver/PendingDriverPanel";
import { ProfileProvider, useProfileContext } from "./context";
import { ApproveDriverModal } from "./modals/ApproveDriverModal";
import { ChangePasswordModal } from "./modals/ChangePasswordModal";
import { DriverLeftModal } from "./modals/DriverLeftModal";
import { RemoveDriverModal } from "./modals/RemoveDriverModal";
import { SaveChangesModal } from "./modals/SaveChangesFirstModal";

export const ProfilePanel = () => {
  const navigate = useNavigate();

  return (
    <ProfileProvider>
      <PageHeader.Actions onBack={() => navigate("../../")} />
      <Content />
    </ProfileProvider>
  );
};

const Content = () => {
  const { driver } = useProfileContext();
  const isPendingApproval = PendingStatuses.includes(driver.status);

  if (isPendingApproval) {
    return (
      <>
        <PendingDriverPanel />
        <ProfileModals />
      </>
    );
  }

  if ([...ActiveStatuses, ...SuspendedStatuses].includes(driver.status))
    return (
      <>
        <ApprovedDriverPanel />
        <ProfileModals />
      </>
    );

  return <ArchivedDriverPanel />;
};

const ProfileModals = () => {
  const { activeModal, setActiveModal } = useProfileContext();

  return (
    <>
      <DriverLeftModal open={activeModal === "driver-left"} onClose={() => setActiveModal(undefined)} />
      <RemoveDriverModal open={activeModal === "remove-driver"} onClose={() => setActiveModal(undefined)} />
      <ChangePasswordModal open={activeModal === "change-password"} onClose={() => setActiveModal(undefined)} />
      <ApproveDriverModal open={activeModal === "approve-driver"} onClose={() => setActiveModal(undefined)} />
      <SaveChangesModal open={activeModal === "save-changes"} onClose={() => setActiveModal(undefined)} />
    </>
  );
};
