import { useNavigate } from "react-router-dom";
import { Button, Icon, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { driverProfileApi } from "@/redux/apis";
import { useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch } from "@/redux/hooks";
import { addToast } from "@/utils";
import { DriverDetail } from "../../../../common/DriverDetail";
import { useProfileContext } from "../../context";

export const PendingDriverStatus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateFleetStatus, { isLoading: isLoadingUpdateStatus }] = useUpdateFleetStatusMutation();
  const { driver, setActiveModal, isFetching: isFetchingDriverProfile } = useProfileContext();
  const isLoading = isLoadingUpdateStatus || isFetchingDriverProfile;
  const { status } = driver;

  const denyDriver = () => {
    updateFleetStatus({ id: driver.uuid, account_status: "removed" })
      .unwrap()
      .then(() => {
        dispatch(driverProfileApi.util.invalidateTags(["DriverProfile"]));
        addToast("success", "Successfully denied driver");
        navigate("../../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderApplicationStatus = () => {
    const statusClassName = "flex flex-row items-center gap-1.5 capitalize";
    let displayStatus = null;

    switch (status) {
      case "pending":
      case "Pending Qualification":
      case "Application in Progress":
      case "Pending Approval":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="MinusCircle" size="sm" variant="Bold" className="text-neutral-dark-gray" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      case "active":
      case "Approved":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="TickCircle" size="sm" variant="Bold" className="text-success" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      case "Declined":
      case "removed":
      case "left":
      case "inactive":
      case "Permanently Terminated":
      case "Retired":
      case "suspended":
      case "Temporarily Suspended":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="Trash" size="sm" variant="Bold" className="text-danger" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      default:
        break;
    }

    return <DriverDetail label="Application" detail={displayStatus} className="flex-1" />;
  };

  return (
    <div className="relative mt-6 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      <Typography variant="title">Driver Status</Typography>
      <div className="mt-6 flex flex-col sm:flex-row">
        {renderApplicationStatus()}
        <div className="mt-3 flex flex-col items-end gap-3 sm:mt-0 sm:flex-row">
          <Button
            variant="primary"
            size="md"
            startIcon="TickCircle"
            iconVariant="Bold"
            iconClassName="text-success"
            className="w-full sm:w-fit"
            onClick={() => setActiveModal("approve-driver")}
            disabled={isLoading}
          >
            Approve Driver
          </Button>
          <Button
            variant="secondary"
            size="md"
            startIcon="CloseCircle"
            iconVariant="Bold"
            iconClassName="text-danger"
            className="w-full sm:w-fit"
            onClick={denyDriver}
            disabled={isLoading}
          >
            Deny Driver
          </Button>
        </div>
      </div>
    </div>
  );
};
