import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { driverProfileApi } from "@/redux/apis";
import { useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch } from "@/redux/hooks";
import { addToast } from "@/utils";
import { useProfileContext } from "../context";

interface DriverLeftModalProps {
  open: boolean;
  onClose: () => void;
}

export const DriverLeftModal = ({ open, onClose }: DriverLeftModalProps) => {
  const dispatch = useAppDispatch();
  const { driver } = useProfileContext();
  const driverName = `${driver.personal.firstName} ${driver.personal.lastName}`;
  const [updateFleetStatus, { isLoading }] = useUpdateFleetStatusMutation();

  const updateStatus = () => {
    updateFleetStatus({ id: driver.uuid, account_status: "left" })
      .unwrap()
      .then(() => {
        dispatch(driverProfileApi.util.invalidateTags(["DriverProfile"]));
        addToast("success", "Successfully updated driver status");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={open} onClose={() => (isLoading ? null : onClose())} className="w-[80vw] md:w-[514px] lg:w-[514px]">
      {isLoading && <Loading />}
      <Typography variant="title">Confirm Driver Departure</Typography>
      <Typography variant="paragraph" className="mt-3">
        Are you sure you want to mark <span className="text-info">{driverName}</span> as &apos;Left&apos;? This action will remove the
        driver from your operations.
      </Typography>
      <div className="mt-[22px] flex flex-row items-center justify-end gap-x-4">
        <Button size="md" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button size="md" variant="primary" className="border-danger bg-danger hover:bg-danger-dark" onClick={updateStatus}>
          Confirm Departure
        </Button>
      </div>
    </Modal>
  );
};
