import { useState } from "react";
import { Button, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useGetDriverIncidentsQuery, useLazyGetDriverIncidentDocumentQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverIncident } from "@/redux/apis/driver/types";
import { downloadFile } from "@/utils";
import { ArchivedStatuses } from "../../../MyDrivers/types";
import { IncidentReport } from "../common/IncidentReport";
import { useAdminActionsContext } from "../context";
import { AddIncidentReportModal } from "../modals/AddIncidentReportModal";
import { ConfirmRemoveIncidentModal } from "../modals/ConfirmRemoveIncidentModal";

export const IncidentManagement = () => {
  const { driver } = useAdminActionsContext();

  const [getIncidentDocument] = useLazyGetDriverIncidentDocumentQuery();
  const { data, isFetching } = useGetDriverIncidentsQuery({ driverId: driver.uuid });
  const [pendingDelete, setPendingDelete] = useState<DriverIncident | null>(null);
  const [step, setStep] = useState<"view" | "add">("view");
  const incidents = data?.incidents || [];

  const handleDownload = (documentId: string) => {
    getIncidentDocument({
      driverId: driver.uuid,
      documentId,
    })
      .unwrap()
      .then((res) => {
        downloadFile(res.filename, res.filename);
      });
  };

  const isArchived = ArchivedStatuses.includes(driver.status);

  return (
    <div className="mt-4 space-y-4 rounded-lg bg-neutral-gray p-4">
      <Typography variant="title">Incident Management</Typography>
      {isFetching ? (
        <Skeleton />
      ) : incidents.length === 0 ? (
        <EmptyState title="" description="No incidents found">
          {!isArchived && (
            <Button variant="secondary" className="mt-4" startIcon="Add" onClick={() => setStep("add")}>
              Add Incident Report
            </Button>
          )}
        </EmptyState>
      ) : (
        <>
          <div className="divide-y divide-neutral-mid-gray">
            {incidents.map((incident) => (
              <IncidentReport key={incident.uuid} onDownload={handleDownload} onDelete={setPendingDelete} incident={incident} />
            ))}
          </div>
          {!isArchived && (
            <Button
              variant="secondary"
              className="mt-4 border border-neutral-dark-gray bg-transparent  "
              startIcon="Add"
              onClick={() => setStep("add")}
            >
              Add Incident Report
            </Button>
          )}
        </>
      )}
      <AddIncidentReportModal driverId={driver.uuid} open={step === "add"} onClose={() => setStep("view")} />
      {pendingDelete && (
        <ConfirmRemoveIncidentModal incident={pendingDelete} open={!!pendingDelete} onClose={() => setPendingDelete(null)} />
      )}
    </div>
  );
};
