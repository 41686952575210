import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, ErrorMessage, Icon, Loading, Modal, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { driverProfileApi } from "@/redux/apis";
import { useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch } from "@/redux/hooks";
import { addToast } from "@/utils";
import { useProfileContext } from "../context";
import { RemoveDriverFormData, removeDriverFormSchema } from "../form";

interface RemoveDriverModalProps {
  open: boolean;
  onClose: () => void;
}

export const RemoveDriverModal = ({ open, onClose }: RemoveDriverModalProps) => {
  const dispatch = useAppDispatch();
  const { name } = useOperator();
  const { driver } = useProfileContext();
  const [updateFleetStatus, { isLoading }] = useUpdateFleetStatusMutation();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<RemoveDriverFormData>({
    resolver: zodResolver(removeDriverFormSchema),
  });

  const handleOnClose = () => {
    if (!isLoading) onClose();
  };

  const onSubmit = handleSubmit((data) => {
    updateFleetStatus({
      id: driver.uuid,
      account_status: "removed",
      account_status_reason: data.reason,
    })
      .unwrap()
      .then(() => {
        dispatch(driverProfileApi.util.invalidateTags(["DriverProfile"]));
        addToast("success", "Successfully updated driver status");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Reason for Removing Driver
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleOnClose} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal open={open} onClose={handleOnClose} className="w-[80vw] md:w-[514px] lg:w-[514px]">
      {isLoading && <Loading />}
      {renderHeader()}
      <form onSubmit={onSubmit} className="mt-3">
        <TextArea placeholder="Enter your reason" hasError={!!formErrors.reason} maxLength={2048} {...register("reason")} />
        <ErrorMessage errors={formErrors} name="reason" />
        <Typography variant="paragraph" className="mt-4 text-neutral-dark-gray">
          Removing a driver will prevent them from doing work with {name}. The reason entered will remain confidential.
        </Typography>
        <Button type="submit" size="lg" variant="primary" className="mt-8 w-full">
          Submit and Remove Driver
        </Button>
      </form>
    </Modal>
  );
};
