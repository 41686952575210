import { Button, Typography } from "@/components/atoms";
import { ArchivedStatuses } from "../../../MyDrivers/types";
import { useAdminActionsContext } from "../context";

export const AdminNotes = () => {
  const { driver } = useAdminActionsContext();
  const isArchived = ArchivedStatuses.includes(driver.status);

  return (
    <div className="mt-4 space-y-4 rounded-lg bg-neutral-gray p-4">
      <Typography variant="title">
        Notes about {driver.personal.firstName} {driver.personal.lastName}
      </Typography>
      <Typography>
        {
          //TODO: Add notes
        }
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
        Suspendisse urna nibh viverra non semper suscipit posuere a pede.
      </Typography>
      {!isArchived && (
        <Button startIcon="Edit2" variant="secondary">
          Update
        </Button>
      )}
    </div>
  );
};
